



















































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  components: {
    AppLogo: () => import('@/components/blocks/AppLogo.vue'),
  },
})
export default class NavMenu extends Vue {
  @Getter('isAlpha') readonly isAlpha!: boolean;

  isOpened: boolean = false;
}
